.language-dropdown {
  background-color: #fff;
  color: #198754;
  border: 1px solid #198754;
  border-radius: 0.375rem;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  appearance: none;
}

/* Hover effect: slightly darken the background so text remains readable */
.language-dropdown:hover {
  background-color: #f8f9fa; /* Light gray on hover */
  cursor: pointer;
}

.language-dropdown:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(25, 135, 84, 0.25);
}

/* Ensure dropdown items remain readable (black text on white background) */
.language-dropdown option {
  color: #000;
}
