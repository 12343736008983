/* welcome.css */
.welcome-message {
  margin-bottom: 2rem; /* Increased spacing before the cards */
}

.welcome-message h2 {
  font-family:
    'Inter',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    sans-serif;
  font-size: 2.25rem; /* Slightly larger for emphasis */
  font-weight: 700; /* Bold for a strong welcome */
  color: #2d3748; /* Darker gray for elegance */
  margin-bottom: 0.5rem;
  line-height: 1.2; /* Improved readability */
}

.welcome-message p {
  font-family:
    'Inter',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    sans-serif;
  font-size: 1.375rem; /* Slightly larger for a friendly tone */
  color: #4a5568; /* Muted gray for balance */
  margin: 0;
}
