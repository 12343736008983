/* Base card styling */
.course-card {
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  background: #ffffff;
  min-height: 300px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.course-card:hover {
  transform: translateY(-6px);
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
}

/* Link styling */
.link-style {
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* Top (Preview) section */
.course-card-preview {
  background: #f7faff;
  padding: 1.5rem;
  color: #2d3748;
  border-bottom: 1px solid #e2e8f0;
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Header section */
.course-preview-header {
  text-align: left;
  margin-bottom: 1rem;
}

.course-name {
  font-family:
    'Inter',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
  color: #1a202c;
}

/* Students preview */
.course-preview-content {
  background: transparent;
  padding: 1rem;
  text-align: left;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

/* New subtitle styling */
.students-subtitle {
  font-family:
    'Inter',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    sans-serif;
  font-size: 0.9rem; /* Slightly smaller than main text for subtitle */
  font-weight: 500;
  color: #718096; /* Muted gray to complement the design */
  text-transform: uppercase; /* For a subtle elegant touch */
  letter-spacing: 0.5px; /* Adds a professional spacing */
  margin-bottom: 0.75rem; /* Space before the students list */
}

/* Students preview list */
.students-preview {
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1;
  justify-content: flex-start;
}

/* Each student's row: initials + name */
.student-preview-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0.5rem 0;
  transition: background-color 0.3s ease;
}

.student-preview-item:hover {
  background-color: #edf2f7;
  border-radius: 6px;
}

/* Circle with the student's initials */
.student-initials {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  font-family:
    'Inter',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

/* Predefined color palette for initials */
.student-initials:nth-child(odd) {
  background-color: #6b5b95;
}

.student-initials:nth-child(even) {
  background-color: #38b2ac;
}

.student-initials:nth-child(3n) {
  background-color: #3182ce;
}

/* Student's full name next to initials */
.student-name {
  font-family:
    'Inter',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    sans-serif;
  font-size: 1.1rem;
  color: #4a5568;
  font-weight: 500;
}

/* Extra item styling */
.extra-item {
  font-family:
    'Inter',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    sans-serif;
  font-size: 1.1rem;
  color: #4a5568;
  font-weight: 600;
}

/* Fallback if no students found */
.no-students {
  font-family:
    'Inter',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    sans-serif;
  color: #718096;
  font-size: 1.1rem;
  font-style: italic;
  text-align: left;
}

/* Footer (Access Code) section */
.course-card-footer {
  padding: 1.5rem;
  text-align: left;
  background: #ffffff;
  border-top: 1px solid #e2e8f0;
}

.access-code {
  font-family:
    'Inter',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    sans-serif;
  font-size: 1rem;
  margin: 0;
  color: #4a5568;
}

.access-code .fw-semibold {
  color: #2b6cb0;
  font-weight: 600;
}

/* Badge style override for "courseBlocked" */
.badge.bg-danger {
  background-color: #e53e3e !important;
  color: #fff !important;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  border-radius: 6px;
  font-family:
    'Inter',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    sans-serif;
}

.access-code-container {
  display: flex;
  align-items: center;
}

.copy-button {
  background: #f7faff;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;
  font-size: 1rem;
  color: #4a5568;
  transition: background-color 0.2s ease;
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.copy-button:hover {
  background: #edf2f7;
}

.copy-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.5);
}
