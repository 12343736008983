/* ------------------------------------------
   1) Container and Page Layout
------------------------------------------- */
.course-page-container {
  width: 100%;
  min-height: 100vh;
  padding: 1.5rem;
  background-color: #f8f9fa;
}

.course-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

/* ------------------------------------------
   2) Header
------------------------------------------- */
.course-header {
  margin-bottom: 1rem;
  text-align: center;
}

/* ------------------------------------------
   3) Teacher & Student View Styles
------------------------------------------- */
.teacher-view,
.student-view {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 20px rgba(69, 90, 100, 0.08);
  padding: 1rem;
}

.students-section {
  margin-top: 1rem;
}

.section-title {
  margin-bottom: 1rem;
  text-align: left;
  font-weight: 600;
  color: #333;
}

.students-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* ------------------------------------------
   4) Rewards Buttons
------------------------------------------- */
.rewards-buttons {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  margin-bottom: 1rem;
}

/* ------------------------------------------
   5) Search Bar
------------------------------------------- */
.search-bar {
  display: flex;
  justify-content: center;
}

.search-input {
  width: 50%;
  max-width: 300px;
  padding: 0.5rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

/* ------------------------------------------
   6) Table Styling
------------------------------------------- */
.students-table-container {
  overflow-x: auto;
}

.table-head {
  background-color: #e9ecef;
}

.sticky {
  position: sticky;
  top: 0;
  background: #e9ecef;
  z-index: 1;
}

.initial-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #fff;
  font-size: 1rem;
}

.highlight {
  background-color: #ffeb3b;
  font-weight: bold;
}

/* ------------------------------------------
   7) Icon Images
------------------------------------------- */
.icon-image {
  width: 24px;
  height: 24px;
}

/* ------------------------------------------
   8) Student View Layout
------------------------------------------- */
.student-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.student-layout {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
}

.avatar-section {
  flex: 1 1 300px;
  text-align: center;
}

.avatar-name {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  color: #333;
}

.avatar-image-container {
  width: 100%;
  max-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.course-info-section {
  flex: 1 1 300px;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 1rem;
  border-radius: 8px;
}

/* ------------------------------------------
   9) Carousel Styles
------------------------------------------- */
.carousel {
  margin-top: 1rem;
}

.carousel-inner {
  height: 300px;
}

.stats-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

/* ------------------------------------------
   10) Progress Circle & Stats
------------------------------------------- */
.progress-circle {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-value {
  position: absolute;
  width: 80%;
  height: 80%;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: #007bff;
  z-index: 1;
}

.stat-label {
  text-align: center;
}

.progress-section {
  width: 100%;
}

.progress-headline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 1rem;
}

.progress-title {
  font-size: 1.2rem;
  font-weight: 700;
  color: #1c2647;
}

.progress {
  height: 30px;
  background: #fff;
  border-top: 5px solid #1c2647;
  border-bottom: 5px solid #1c2647;
  border-radius: 0;
  margin: 0 1rem 1rem;
  overflow: visible;
  position: relative;
}

.progress-bar {
  border: none;
  height: 100%;
  transition: width 0.5s ease;
}

/* ------------------------------------------
   11) Icon Sizes
------------------------------------------- */
.icon {
  width: 40px;
  height: 40px;
}

/* ------------------------------------------
   12) Modal Styles
------------------------------------------- */
.info-modal {
  z-index: 1050;
}

.info-modal .modal-dialog {
  max-width: 800px;
}

/* Overwrite default .card styling for the user card */
.info-modal .user-card-full {
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border: none;
}

/* Gradient background on the left side (avatar area) */
.info-modal .user-profile {
  background: linear-gradient(135deg, #6d5dfb, #8c67f5);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  padding: 1rem;
}

/* Round avatar image inside the modal */
.img-radius {
  border-radius: 50%;
  max-width: 100px;
}

/* Right-side panel in the modal */
.info-modal .card-block {
  position: relative;
  padding: 1.5rem;
  background-color: #fff;
}

/* Close button in top-right corner */
.close-modal {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 1.3rem;
  background: transparent;
  border: none;
  opacity: 0.7;
  transition: opacity 0.2s ease;
}
.close-modal:hover {
  opacity: 1;
}

/* Section headings within the modal */
.modal-section-title {
  margin: 1rem 0 0.75rem;
  font-weight: 600;
  font-size: 1rem;
  border-bottom: 1px solid #ddd;
  padding-bottom: 0.5rem;
  color: #333;
}

/* Labels above inputs/text */
.modal-label {
  font-weight: 600;
  margin-bottom: 0.25rem;
  display: inline-block;
}

/* Muted text color override */
.text-muted {
  color: #6c757d !important;
}

/* For clickable text (e.g. password copy) */
.pointer {
  cursor: pointer;
}

/* ------------------------------------------
   13) Modal Drop Animations
------------------------------------------- */
@keyframes modalDropIn {
  from {
    transform: translateY(-10vh);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes modalDropOut {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-10vh);
    opacity: 0;
  }
}

.modal-drop-in {
  animation: modalDropIn 0.3s ease forwards;
}

.modal-drop-out {
  animation: modalDropOut 0.3s ease forwards;
}

/* ------------------------------------------
   14) Responsive Adjustments
------------------------------------------- */
@media (min-width: 992px) {
  .course-content {
    padding: 2rem;
  }
  .teacher-view,
  .student-view {
    padding: 2rem;
  }
}
