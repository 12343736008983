.loading-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.7); /* semi-transparent white background */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
}

.loading-overlay-2 {
  display: flex; /* Set to flex container */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  background-color: rgba(255, 255, 255, 0.7); /* Semi-transparent white background */
  margin-top: 0;
}
