body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.option-button {
  height: 100%;
}

.media-object {
  height: 75px;
}

.ql-toolbar {
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

.ql-container {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
}

.ql-editor {
  min-height: 6em;
}

.is-invalid {
  .ql-toolbar {
    border-top-color: var(--bs-form-invalid-color) !important;
    border-left-color: var(--bs-form-invalid-color) !important;
    border-right-color: var(--bs-form-invalid-color) !important;
  }

  .ql-container {
    border-bottom-color: var(--bs-form-invalid-color) !important;
    border-left-color: var(--bs-form-invalid-color) !important;
    border-right-color: var(--bs-form-invalid-color) !important;
  }
}
